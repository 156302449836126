import { environment } from "src/environments/environment";

export interface APIDataModel {
  version: string;
  apiEndPoint?: string;
  url?: string;
  host?: string;
  type?: string;
}

export const API_END_POINTS = {
  CreateSupportTicket: {
    type: 'helpDesk',
    version: '1',
    apiEndPoint: 'helpdesk/CreateTicket',
    host: environment.helpDeskServiceHost,
  },
  // User
  GenerateOtp: {
    type: "UserManagement",
    version: "1",
    apiEndPoint: "OTP/GenerateOTP",
    host: environment.userManagementServiceHost,
  },
  ValidateOtp: {
    type: "UserManagement",
    version: "1",
    apiEndPoint: "OTP/ValidateOTP",
    host: environment.userManagementServiceHost,
  },



  // Roles
  GetAllRoles: {
    type: "UserManagement",
    version: "1",
    apiEndPoint: "Role/all",
    host: environment.userManagementServiceHost,
  },
  UpdateUser: {
    type: 'UserMangement',
    version: '1',
    apiEndPoint: 'User/update',
    host: environment.userManagementServiceHost,
  },
  GetUserById: {
    type: 'UserMangement',
    version: '1',
    apiEndPoint: 'User',
    host: environment.userManagementServiceHost,
  },
  GetAllByUsers: {
    type: "AttendanceManagement",
    version: "1",
    apiEndPoint: "Attendance/allbyuser",
    host: environment.attendanceManagementServiceHOst,
  },
  GetAttendanceInDateRanges: {
    type: "AttendanceManagement",
    version: "1",
    apiEndPoint: "StaffAttendance/GetStaffAttendanceByDateRange",
    host: environment.attendanceManagementServiceHOst,
  },
  ChangeAttendance: {
    type: "AttendanceManagement",
    version: "1",
    apiEndPoint: "Attendance/changeattendance",
    host: environment.attendanceManagementServiceHOst,
  },
  DeleteAttendance: {
    type: "AttendanceManagement",
    version: "1",
    apiEndPoint: "Attendance/delete",
    host: environment.attendanceManagementServiceHOst,
  },
  GetAllShifts: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Shift/GetAllShift",
    host: environment.roasteringManagementServiceHOst,
  },
  AddShift: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Shift/add",
    host: environment.roasteringManagementServiceHOst,
  },
  UpdateShift: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Shift/update",
    host: environment.roasteringManagementServiceHOst,
  },
  DeleteShift: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Shift/Delete",
    host: environment.roasteringManagementServiceHOst,
  },
  AddRosters: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Rostering/add",
    host: environment.roasteringManagementServiceHOst,
  },
  UpdateRosters: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Rostering/update",
    host: environment.roasteringManagementServiceHOst,
  },
  GetAllRoster: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Rostering/GetAllRoster",
    host: environment.roasteringManagementServiceHOst,
  },
  GetAllRosterByStaff: {
    type: "ShiftManagement",
    version: "1",
    apiEndPoint: "Rostering/GetAllRosterByStaff",
    host: environment.roasteringManagementServiceHOst,
  },
  GetAllGxSchedule: {
    type: "GXPlanner",
    version: "1",
    apiEndPoint: "gxsession/search?",
    host: environment.gxPlannerManagementServiceHOst,
  },
  GetGxSessionById: {
    type: "GXPlanner",
    version: "1",
    apiEndPoint: "gxsession/session/fetch?",
    host: environment.gxPlannerManagementServiceHOst,
  },

  AddSchedule: {
    type: "GXPlanner",
    version: "1",
    apiEndPoint: "gxschedule/add",
    host: environment.gxPlannerManagementServiceHOst,
  },
  AddSession: {
    type: "GXPlanner",
    version: "1",
    apiEndPoint: "gxsession/add",
    host: environment.gxPlannerManagementServiceHOst,
  },
  UpdateSession: {
    type: "GXPlanner",
    version: "1",
    apiEndPoint: "gxsession/update",
    host: environment.gxPlannerManagementServiceHOst,
  },
  DeleteSchedule: {
    type: "GXPlanner",
    version: "1",
    apiEndPoint: "GXSchedule/delete",
    host: environment.gxPlannerManagementServiceHOst,
  },
  CancelSession: {
    type: "GXSessions",
    version: "1",
    apiEndPoint: "gxsession/cancel",
    host: environment.gxPlannerManagementServiceHOst,
  },
  //
  // GetOfflineVenueLeads: {
  //   type: "VenueLeadManagement",
  //   version: "1",
  //   apiEndPoint: "OfflineVenueLead/GetAllVenueLead?",
  //   host: environment.venueLeadServiceHost,
  // },
  GetOfflineVenueLeads: {
    type: 'VenueLeadManagement',
    version: '1',
    apiEndPoint: 'OfflineVenueLead/search',
    host: environment.venueLeadServiceHost,
},
  AddOfflineVenueLeads: {
    type: "VenueLeadManagement",
    version: "1",
    apiEndPoint: "OfflineVenueLead/add",
    host: environment.venueLeadServiceHost,
  },
  UpdateOfflineVenueLeads: {
    type: "VenueLeadManagement",
    version: "1",
    apiEndPoint: "OfflineVenueLead/update",
    host: environment.venueLeadServiceHost,
  },
  GetServiceType: {
    type: "VenueManagement",
    version: "1",
    apiEndPoint: "ServicesMasterData/all",
    host: environment.venueManagementServiceHOst,
  },

  // checkin
  GetAllExistingCustomer: {
    type: "CheckinManagement",
    version: "1",
    apiEndPoint: "CustomerProfile/GetAllExistingCustomerlist/",
    host: environment.customerProfileServiceHost,
  },

  GetRecentlyAssignedCustomers: {
    type: "CheckinManagement",
    version: "1",
    apiEndPoint: "CustomerProfile/GetRecentlyAssignedCustomers/",
    host: environment.customerProfileServiceHost,
  },

  GetTrialCustomers: {
    type: "CheckinManagement",
    version: "1",
    apiEndPoint: "CustomerProfile/GetTrialCustomers?VenueId=",
    host: environment.customerProfileServiceHost,
  },
  GetAllVenues: {
    type: "VenueManagement",
    version: "1",
    apiEndPoint: "VenueBaseProfile/all",
    host: environment.venueManagementServiceHOst,
  },
  GetAllVenuesByUser: {
    type: "UserManagement",
    version: "1",
    apiEndPoint: "User/GetVenueByUserId",
    host: environment.userManagementServiceHost,
  },

  //targets
  GetAllTargetList: {
    type: "TargetManagement",
    version: "1",
    apiEndPoint: "Targets/GetAllTargets",
    host: environment.targetManagementService,
  },

  GetTargetById: {
    type: "TargetManagement",
    version: "1",
    apiEndPoint: "Targets/GetTargetById",
    host: environment.targetManagementService,
  },

  AddTarget: {
    type: "TargetManagement",
    version: "1",
    apiEndPoint: "Targets/add",
    host: environment.targetManagementService,
  },
  DeleteTarget: {
    type: "TargetManagement",
    version: "1",
    apiEndPoint: "Targets/",
    host: environment.targetManagementService,
  },
  UpdateTarget: {
    type: "TargetManagement",
    version: "1",
    apiEndPoint: "Targets/update",
    host: environment.targetManagementService,
  },

  //staff
  GetAllStaff: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/GetAllStaff",
    host: environment.venueStaffManagementServiceHOst,
  },

  GetStaffBySearch: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/search",
    host: environment.venueStaffManagementServiceHOst,
  },

  GetVenueStaffById: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/GetStaffById",
    host: environment.venueStaffManagementServiceHOst,
  },

  AddVenueStaff: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/add",
    host: environment.venueStaffManagementServiceHOst,
  },
  DeleteVenueStaff: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/",
    host: environment.venueStaffManagementServiceHOst,
  },
  UpdateVenueStaff: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/update?Id=",
    host: environment.venueStaffManagementServiceHOst,
  },

  UploadStaffFiles: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "filehandler/upload",
    host: environment.filedetailsfitzonedev,
  },
  GetStaffFiles: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "VenueStaff/DownloadFile",
    host: environment.venueStaffManagementServiceHOst,
  },
  GetStaffProfile: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "filehandler/fetch/type",
    host: environment.filedetailsfitzonedev,
  },
  GetStaffGallery: {
    type: "StaffManagement",
    version: "1",
    apiEndPoint: "filehandler/download",
    host: environment.filedetailsfitzonedev,
  },

  //PT Packages

  GetAllPtPackages: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition/all?",
    host: environment.fitzonePersonalTrainingApiDev,
  },

  GetPtPackageById: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition",
    host: environment.fitzonePersonalTrainingApiDev,
  },

  AddPtPackage: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition/add",
    host: environment.fitzonePersonalTrainingApiDev,
  },

  UpdatePtPackage: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition/update?Id=",
    host: environment.fitzonePersonalTrainingApiDev,
  },

  DeletePtPackage: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition/delete/",
    host: environment.fitzonePersonalTrainingApiDev,
  },

  //PT Trainers

  GetAllPtTrainers: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "VenueStaff/GetAllStaff?",
    host: environment.venuestafffitzonedev,
  },

  GetPtTrainerById: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "VenueStaff/GetStaffById?",
    host: environment.venuestafffitzonedev,
  },

  AddPtTrainer: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition/add",
    host: environment.venuestafffitzonedev,
  },

  UpdatePtTrainer: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "VenueStaff/update?Id=",
    host: environment.venuestafffitzonedev,
  },

  DeletePtTrainer: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "PersonalTraining/PTPackageDefinition/delete/",
    host: environment.venuestafffitzonedev,
  },

  UploadProfile: {
    type: "PersonalTraining",
    version: "1",
    apiEndPoint: "VenueStaff/UploadDocument?",
    host: environment.venuestafffitzonedev,
  },

  // PT Transfer

  GetUnassignedCustomers:{
    type: "PTTransfer",
    version: "1",
    apiEndPoint: "query/unassignedcustomers/",
    host: environment.fitzonePersonalTrainingApiDev,
  },

  //customer Management
  GetAllCustomersList: {
    type: "customerMangement",
    version: "1",
    apiEndPoint: "CustomerProfile/search?",
    host: environment.customerMgmtServiceHost,
  },
  GetAllUnassignedCustomersList: {
    type: "customerMangement",
    version: "1",
    apiEndPoint: "CustomerProfile/GetUnAssignedCustomers/",
    host: environment.customerMgmtServiceHost,
  },
 
  createOrder: {
    type: "Order",
    version: "1",
    apiEndPoint: "order/create",
    host: environment.orderMgmtServiceHost,
  },
  updateTransaction: {
    type: "Order",
    version: "1",
    apiEndPoint: "order/transactionstatus",
    host: environment.orderMgmtServiceHost,
  },
  GetCustomerById: {
    type: 'customerMangement',
    version: '1',
    apiEndPoint: 'CustomerProfile/allbyuser',
    host: environment.customerMgmtServiceHost,
  },
  AddCustomer: {
    type: 'customerMangement',
    version: '1',
    apiEndPoint: 'CustomerProfile/add',
    host: environment.customerMgmtServiceHost,
  },
  GetOrderById: {
    type: 'orderMangement',
    version: '1',
    apiEndPoint: 'order/',
    host: environment.orderMgmtServiceHost,
},

GetAllOrdersList: {
  type: 'orderMangement',
  version: '1',
  apiEndPoint: 'order/search?',
  host: environment.orderMgmtServiceHost,
},

DownloadInvoice: {
  type: 'orderMangement',
  version: '1',
  apiEndPoint: 'order/invoice/',
  host: environment.orderMgmtServiceHost,
},
SendEmail: {
  type: 'orderMangement',
  version: '1',
  apiEndPoint: 'order/invoice/email/',
  host: environment.orderMgmtServiceHost,
},
PauseSubscription: {
  type: 'subscriptionMangement',
  version: '1',
  apiEndPoint: 'SubscriptionRecord/',
  host: environment.subscriptionMgmtServiceHost,
},
GetAllSubscriptionRecord: {
  type: 'subscriptionRecordMangement',
  version: '1',
  apiEndPoint: 'SubscriptionRecord/Customer/',
  host: environment.subscriptionRecordServiceHost,
},
GetAllSubscription: {
  type: 'subscriptionMangement',
  version: '1',
  apiEndPoint: 'Subscription/search',
  host: environment.subscriptionMgmtServiceHost,
},
GetSessionCount: {
  type: 'customerMangement',
  version: '1',
  apiEndPoint: 'query/sessionscount?',
  host: environment.sessionServiceHost,
},

};
