import { OnInit } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  permissionDashboard = false;
  permissionGetAllVenueLead = false;
  permissionGetAllExistingCustomers = false;
  permissionGetRecentlyAssignedCustomers = false;
  permissionGetTrialCustomers = false;
  permissionGetAllStaff = false;
  permissionPTPackageDefinition = false;
  permissionGetStaffAttendanceByDateRange = false;
  permissionGetAllShift = false;
  permissionGetAllRoster = false;
  permissionGetAllGxSchedule = false;
  permissionAllCustomer = false;

  ngOnInit() {
    const allAccess = JSON.parse(localStorage.getItem('Access') as string);
    console.log('!!!!!!!!!', allAccess);
    this.permissionDashboard = allAccess.includes('Dashboard') ? true : false;
    this.permissionGetAllVenueLead = allAccess.includes('VenueLead_SearchLeadsWithPagination') ? true : false;
    this.permissionGetAllExistingCustomers = allAccess.includes('CustomerProfile_SearchCustomerWithPagination') ? true : false;
    this.permissionGetRecentlyAssignedCustomers = allAccess.includes('CustomerProfile_GetRecentlyAssignedCustomers') ? true : false;
    this.permissionGetTrialCustomers = allAccess.includes('CustomerProfile_GetTrialCustomers') ? true : false;
    this.permissionGetAllStaff = allAccess.includes('VenueStaff_SearchVenueStaffWithPagination') ? true : false;
    this.permissionPTPackageDefinition = allAccess.includes('PersonalTraining_GetAllPTPackageDefinition') ? true : false;
    this.permissionGetStaffAttendanceByDateRange = allAccess.includes('Attendance_GetStaffAttendanceByDateRange') ? true : false;
    this.permissionGetAllShift = allAccess.includes('Shift_GetAllShift') ? true : false;
    this.permissionGetAllRoster = allAccess.includes('Rostering_GetAllRoster') ? true : false;
    this.permissionGetAllGxSchedule = allAccess.includes('GxSessions_SearchGXclasswithPagination') ? true : false;
    this.permissionAllCustomer = allAccess.includes('CustomerProfile_SearchCustomerWithPagination' )? true : false;
    this.model = [
      {
        items: [
          {
            label: "Dashboards",
            icon: "pi pi-fw pi-home",
            routerLink: ["/home"],
            show: true
          },
          {
            label: "Venue Leads",
            icon: "pi pi-fw pi-image",
            routerLink: ["/home/venue-leads"],
            show: this.permissionGetAllVenueLead
          },
          {
            label: "Checkins",
            icon: "pi pi-fw pi-check-circle",
            routerLink: ["/home/checkins"],
            show: this.permissionGetAllExistingCustomers || this.permissionGetRecentlyAssignedCustomers || this.permissionGetTrialCustomers
          },
          {
            label: 'Customer Management',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/home/customer-management/customers'],
            show: this.permissionAllCustomer,
        },
          {
            label: "Personal Training",
            icon: "pi pi-fw pi-book",
            routerLink: ["/"],
            show: this.permissionGetAllStaff || this.permissionPTPackageDefinition,
            items: [
              {
                label: "PT Purchase",
                icon: "pi pi-fw pi-bookmark",
                routerLink: ["/home/pt-purchase"],
                show: this.permissionGetAllStaff
              },
              {
                label: "Packages",
                icon: "pi pi-fw pi-credit-card",
                routerLink: ["/home/pt-packs"],
                show: this.permissionPTPackageDefinition
              },
              {
                label: "PT Trainers",
                icon: "pi pi-fw pi-credit-card",
                routerLink: ["/home/pt-trainers"],
                show: this.permissionGetAllStaff
              },
              {
                label: "PT Transfer",
                icon: "pi pi-fw pi-users",
                routerLink: ["/home/pt-transfer"],
                show: this.permissionGetAllStaff
              },
              // {
              //   label: "Assign PTPacks",
              //   icon: "pi pi-fw pi-credit-card",
              //   routerLink: ["/home/pt-assignpacks"],
              //   show: this.permissionGetAllStaff
              // },
            ],
          },
          {
            label: "Staff Management",
            icon: "pi pi-fw pi-user-edit",
            routerLink: ["/"],
            show: this.permissionGetAllStaff,
            items: [
              {
                label: "Staff",
                icon: "pi pi-fw pi-users",
                routerLink: ["/home/staff"],
                show: this.permissionGetAllStaff
              },
              {
                label: "Attendance",
                icon: "pi pi-fw pi-check",
                routerLink: ["/home/staff-attendance"],
                show: this.permissionGetStaffAttendanceByDateRange
              },
            ],
          },
          {
            label: "Shift Management",
            icon: "pi pi-fw pi-user-edit",
            routerLink: ["/home/shifts-management"],
            show: this.permissionGetAllShift || this.permissionGetAllRoster
          },
          {
            label: "Target Management",
            icon: "pi pi-fw pi-user-edit",
            routerLink: ["/home/target-management"],
            show: this.permissionGetAllGxSchedule
          },
          {
            label: "GX Planner",
            icon: "pi pi-fw pi-calendar",
            routerLink: ["/home/gx-planner"],
            show: this.permissionGetAllGxSchedule
          },
          {
            label: "Reports",
            icon: "pi pi-fw pi-chart-bar",
            show: true,
            items: [
              {
                label: "Settlements",
                icon: "pi pi-fw pi-dollar",
                routerLink: ["/home/reports/settlements"],
                show: true
              },
             
            ],
          },
          {
            label: "Support",
            icon: "pi pi-fw pi-comments",
            routerLink: ["/home/support"],
            show: true
          },
          {
            label: "Logout",
            icon: "pi pi-fw pi-sign-out",
            routerLink: ["/login"],
            show: true
          },
        ],
      },
    ];
  }
}
